<script>
import Multiselect from "@vueform/multiselect";
import axios from "axios";
import Swal from "sweetalert2";
// import Swal from "sweetalert2";
export default {
  props: ["simulasi_data"],
  components: {
    Multiselect,
  },
  data() {
    return {
      // variable Page
      option_tipe_status:
        [
          { value: "belum", label: "Belum" },
          { value: "berlangsung", label: "Berlangsung" },
          { value: "selesai", label: "Selesai" },
        ],
      form_data: {
        id_jadwal: this.simulasi_data.jadwal_id,
        id_simulasi: this.simulasi_data.simulasi_id,
        pin: this.simulasi_data.pin,
        nip_asesi: this.simulasi_data.nip_asesi,
        status: this.simulasi_data.status,
      },
      check_status: false,
    };
  },
  mounted() {
  },
  methods: {
    StoreData() {
      let self = this;
      let formData = new FormData();
      formData.append("id_jadwal", self.form_data.id_jadwal);
      formData.append("id_simulasi", self.form_data.id_simulasi);
      formData.append("pin", self.form_data.pin);
      formData.append("nip_asesi", self.form_data.nip_asesi);
      formData.append("status", self.form_data.status);

      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });

      var config_store_data = {
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "monitoring/update-status-duration",
        data: formData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          "content-type": "multipart/form-data",
        },
      };

      axios(config_store_data)
        .then(function (response) {
          console.log(response);
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Anda akan diarahkan ke halaman monitoring pelaksanaan files segera",
            timer: 1000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              window.location.reload();
            }
          });
        })
        .catch(function (error) {
          self.axiosCatchError = error.response.data.data;
          Swal.close();
        });
    },
  },
};
</script>
<template>
  <b-form class="p-2" @submit.prevent="StoreData">
    <div class="row">
      <div class="col-sm-12">
        <table class="table table-borderless">
          <tr>
            <td>NIP</td>
            <td>:</td>
            <td>{{ this.simulasi_data.nip_asesi }}</td>
          </tr>
          <tr>
            <td>Nama</td>
            <td>:</td>
            <td>{{ this.simulasi_data.nama_asesi }}</td>
          </tr>
          <tr>
            <td>PIN</td>
            <td>:</td>
            <td>{{ this.simulasi_data.pin }}</td>
          </tr>
        </table>
      </div>
      <div class="col-sm-12">
        <label>Status</label>
        <multiselect v-model="form_data.status" :options="option_tipe_status" :close-on-select="true"
          placeholder="Pilih Status"></multiselect>
      </div>
    </div>
    <div class="col-sm-12">
      <div class="form-check mt-2">
        <input class="form-check-input" type="checkbox" v-model="check_status">
        <label class="form-check-label" for="flexCheckDefault">
          Centang ini jika anda yakin mengubah status
        </label>
      </div>

    </div>
    <hr />
    <div class="row mt-2">
      <div class="col-12 text-end">
        <button v-if="check_status" type="submit" class="btn btn-primary btn-md">
          <i class="fa fa-edit"></i> Simpan
        </button>
        <button v-else class="btn btn-primary btn-md disabled">
          <i class="fa fa-edit"></i> Simpan
        </button>
      </div>
    </div>
  </b-form>
</template>
